import React, { useState } from 'react';
import { Tabs as BaseTabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import styles from './Tabs.module.scss';

const Tabs = ({ tabData }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        tabData.length > 0 && 
            <BaseTabs
                defaultValue={0}
                value={value}
                onChange={handleChange}
                className={styles.tabsContainer}
            >
                <BaseTabsList className={styles.tabsList}>
                    {tabData.map((tab, index) => (
                        <BaseTab
                            key={index}
                            className={`${styles.tab} ${value === index ? styles.active : ''}`}
                        >
                            {tab.label}
                        </BaseTab>
                    ))}
                </BaseTabsList>
                {tabData.map((tab, index) => (
                    <BaseTabPanel
                        key={index}
                        value={index}
                        className={styles.tabPanel}
                    >
                        {tab.content}
                    </BaseTabPanel>
                ))}
            </BaseTabs>
    );
};

export default Tabs;
