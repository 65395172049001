import React, { useState } from "react";
import {
	Datagrid,
	TextField,
	DateField,
	FunctionField,
	Edit,
	TextInput,
	BooleanInput,
	DateTimeInput,
	TabbedForm,
	FormTab,
	ReferenceManyField,
	EditButton,
	Labeled,
	Toolbar,
	useRecordContext,
	useTranslate,
	Pagination
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { useSettingsContext } from "../../utils/SettingsProvider";
import { Box, Stack } from "@mui/material";
import { ShareInfoBox } from "./DebateCreate";
import { getLocaleNeutralThesis } from "./DebateCreate";
import MultiSelectInput from "../../inputs/MultiSelectInput";
import LinkArticleInput from "../../inputs/LinkArticleInput";
import ImageUrlInput from "../../inputs/ImageUrlInput";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";
import DebateTitle from "./DebateTitle";
import EmbedField from "../../inputs/EmbedField";
import CopyLinkField from "../../inputs/CopyLinkField";
import FormSectionTitle from "../../utils/FormSectionTitle";
import ContextMultiSelectInput from "../../inputs/ContextMultiSelectInput";
import contextExample from "../../images/context_sources.png";
import HelperText from "../../utils/HelperText";
import PositionInput from "../../inputs/PositionInput";
import SubApplicationSelectInput from "../../inputs/SubApplicationSelectInput";
import ExportButton from "../../utils/ExportButton";
import dayjs from '../../utils/dayJsSetup';

const useStyles = makeStyles((theme) => ({
	exportButtonContainer: {
		width: "100%",
		textAlign: "right"
	}
}));

const DebateEdit = () => {
	const classes = useStyles();
	const translate = useTranslate();
	const config = useSettingsContext();
	const [debateTitle, setDebateTitle] = useState();

	const transformData = (data, { previousData }) => {
		let positionList = [data.pro_thesis, data.against_thesis];
		if (data.pro_thesis && data.against_thesis && data.has_neutral_thesis) {
			positionList = [data.pro_thesis, data.against_thesis, (config?.settings?.vote?.neutralThesisName || data.language ? getLocaleNeutralThesis(data.language) : translate("resources.groups.fields.neutral_thesis"))];
		}

		let tagObjects = [];
		let tag_id_array = [];

		if (data.tag_list) {
			data.tag_list.forEach((tag) => tagObjects.push({ name: tag.trim() }));
		}
		if (data.tag_id_list) {
			tag_id_array = data.tag_id_list.map(item => item.id);
			if (config?.settings?.modules?.allowTagDuplicate === false) { data.tag_id_list.forEach(item => tagObjects.push({ name: item.name })); }
		}

		return {
			...((data.has_neutral_thesis && previousData.group_context.positions.length < 2) && { neutral_thesis: (config?.settings?.vote?.neutralThesisName || data.language ? getLocaleNeutralThesis(data.language) : translate("resources.groups.fields.neutral_thesis")) }),
			...(data.name !== previousData.name && { name: data.name }),
			...(data.subtitle !== previousData.subtitle && { subtitle: data.subtitle }),
			...(data.is_active !== previousData.is_active && { is_active: data.is_active }),
			...(data.ends_at !== previousData.ends_at && { ends_at: data.ends_at }),
			...(data.published_at !== previousData.published_at && { published_at: data.published_at, is_published: false }),
			...(data.published_at !== previousData.published_at && data.published_at === null && { published_at: data.published_at, is_published: true, created_at: dayjs() }),
			...(data.is_default !== previousData.is_default && { is_default: data.is_default }),
			...(data.language !== previousData.language && { language: data.language }),
			...(data.image_copyright !== previousData.image_copyright && { image_copyright: data.image_copyright }),
			...(data.image_alt !== previousData.image_alt && { image_alt: data.image_alt }),
			...(data.is_pending !== previousData.is_pending && { is_pending: data.is_pending }),
			...(data.is_accepted !== previousData.is_accepted && { is_accepted: data.is_accepted }),
			...(data.origin_image_url !== previousData.origin_image_url && { origin_image_url: data.origin_image_url, is_user_image: true }),
			...(data.context_source_list !== previousData.context_source_list && { context_source_list: JSON.stringify(data.context_source_list) }),
			...(data.content_uids && { content_uids: data.content_uids.join(",") }),
			...(data.tag_id_list && JSON.stringify(data.tag_id_list) !== JSON.stringify(previousData.content_tags.map(tag => tag.tag.id)) && { tag_id_list: tag_id_array.join(',') }),
			...(data.tag_list && JSON.stringify(tagObjects.map(t => t.name)) !== JSON.stringify(previousData.group_context.tags.map(t => t.name)) && { tag_objects: JSON.stringify(tagObjects) }),
			...(data.sub_application_id !== previousData.sub_application_id && { sub_application_id: data.sub_application_id }),
			...(JSON.stringify(positionList) !== JSON.stringify(previousData.group_context.positions.map(p => p.name)) && { position_list: JSON.stringify(positionList) })
		};
	}

	const validateDate = (value) => {
		if (!value) { return undefined; }
		
		const selectedDate = new Date(value);
		if (isNaN(selectedDate.getTime())) { return translate('resources.groups.labels.date_invalid'); }
	
		const now = new Date();
		now.setMilliseconds(0);
		selectedDate.setMilliseconds(0);
	
		if (selectedDate < now) { return translate("resources.groups.labels.date_invalid"); }
	
		return undefined;
	};
	


	return (
		<>
			<Edit title={<DebateTitle />} redirect='list' mutationMode="pessimistic" transform={transformData}>
				<TabbedForm toolbar={<Toolbar />}>
					<FormTab label={translate("pos.tabs.main")}>
						<ExportArgumentsButton />
						<FormSectionTitle title={translate('resources.groups.form_title.debate')} />
						<CopyLinkField source={'direct_url'} />
						<TextInput 
							source='name' 
							fullWidth 
							variant='outlined' 
							helperText={false}
							required
							onChange={(event) => setDebateTitle(event.target?.value)}
						/>
						{ config?.settings?.actions?.allowDebateSubtitle === true && 
							<Box mt={1} width={"100%"}>
								<TextInput source="subtitle" fullWidth variant="outlined" multiline minRows={2} helperText={translate("resources.groups.labels.subtitle")} />
							</Box>
						}
						<PositionInput />
						<Stack direction={"row"}>
							{config?.settings?.translation?.enable === true &&
								<Box mb={1} mr={1}>
									<LanguageSelectInput label={translate("resources.settings.fields.language")} />
								</Box>
							}
							{config?.settings?.actions?.allowDebateBranding === true && (
								<Box>
									<SubApplicationSelectInput />
								</Box>
							)}
						</Stack>
						{config?.settings?.modules?.sources !== false &&
							<LinkArticleInput
								tagSource={"tag_id_list"}
								tagInitialSource={"content_tags"}
								tagFormatInitial={(initialValue) => initialValue?.map(e => e.tag)}
							/>
						}
						{config?.settings?.modules?.contextSources &&
							<>
								<FormSectionTitle
									isNew
									example
									exampleTitle={translate('resources.groups.form_title.context')}
									exampleDescription={translate('resources.groups.example.context')}
									exampleImageUrl={contextExample}
									title={translate('resources.groups.form_title.context')}
									descriptionOne={translate('resources.groups.form_description.context_first_description')}
									descriptionTwo={translate('resources.groups.form_description.context_second_description')}
								/>
								<Box sx={{ width: '100%' }} mb={1}>
									<ContextMultiSelectInput 
										query={debateTitle} 
									/>
								</Box>
							</>
						}
						<Box flex={1} mt={2}>
							<Labeled label={translate("resources.groups.labels.image")}>
								<ImageUrlInput
									source="origin_image_url"
									initialSource={"image_url"}
									helperText={translate("resources.groups.fields.image_info")}
									errorMessage={translate('resources.consultations.labels.image_error')}
								/>
							</Labeled>
						</Box>
						<Stack direction={"row"} mt={2}>
							<Box flex={1}>
								<TextInput source="image_copyright" variant="outlined" fullWidth helperText={false} />
								<HelperText text={translate('pos.main.not_required')} />
							</Box>
							<Box flex={1} ml={2}>
								<TextInput source="image_alt" variant="outlined" fullWidth helperText={false} />
								<HelperText text={translate('pos.main.not_required')} />
							</Box>
						</Stack>
						<Labeled>
							<DateField source='created_at' showTime={true} />
						</Labeled>
					</FormTab>
					<FormTab label={translate("pos.tabs.advanced")}>
						<MultiSelectInput
							isCreatable
							isAsync
							source='tag_list'
							initialSource="group_context.tags"
							format={(v) => v?.map((e) => {
								return { label: e.display_name.trim(), value: e.name.trim() };
							})}
							resource={"tags"}
							sort={{ field: "taggings_count", order: "DESC" }}
							label={translate("pos.main.tags")}
							placeholder={translate("resources.groups.labels.tag_multiselect")}
							helperText={translate("resources.helpers.tags")}
							noOptionsMessage={translate("resources.groups.labels.no_elements")}
							fullWidth
						/>
						<Box flex={1} mt={3} style={{ display: "flex" }}>
							<BooleanInput source="is_active" format={v => !v} parse={v => !v} defaultValue="true" label={translate('resources.groups.labels.is_active')} />
							{config.settings?.synthesis?.defaultGroup === true &&
								<BooleanInput source="is_default" fullwidth="true" label={translate('resources.groups.labels.pinned_debate')} />
							}
							<BooleanInput source="is_pending" label={translate('resources.groups.fields.is_pending')} />
						</Box>
						<Box flex={1} mb={1}>
							<DateTimeInput source="ends_at" label={translate('resources.groups.labels.ends_at')} validate={validateDate} />
						</Box>
						<FunctionField
							render={(record) =>
								record?.published_at ? (
									<Box flex={1} mb={3}>
										<DateTimeInput source="published_at" label={translate('resources.groups.labels.draft')} helperText={translate("resources.groups.fields.draft_info")} />
									</Box>
								) : null
							}
						/>
						<EmbedField type='oEmbed' resource="group" />
						<EmbedField type='iframe' resource="group" />
						{/* <EmbedField type='vote_iframe' resource="group" /> */}
						{config?.settings?.synthesis?.allowWidget === true &&
							<EmbedField type='widget' resource="group" />
						}
					</FormTab>
					<FormTab path='messages' label={translate("resources.messages.name", { smart_count: 2 })}>
						<ExportArgumentsButton />
						<ReferenceManyField
							addLabel={false}
							sort={{ field: 'created_at', order: 'DESC' }}
							reference='messages'
							filter={{ group_type: "Group" }}
							target='group_id'
							source='identifier'
							pagination={<Pagination />}
						>
							<Datagrid>
								<DateField source='created_at' />
								<TextField source='content' />
								<EditButton style={{ padding: 0 }} />
							</Datagrid>
						</ReferenceManyField>
					</FormTab>
				</TabbedForm>
			</Edit>
			<ShareInfoBox />
		</>
	);
};

const ExportArgumentsButton = () => {
	const record = useRecordContext();
	const classes = useStyles();
	const translate = useTranslate();

	if (record) {
		return (
			<div className={classes.exportButtonContainer}>
				<ExportButton dataName={"Message"} filters={{ group_id: record.identifier }} sort={{ field: "created_at", order: "DESC" }} />
			</div>
		)
	}
}

export default DebateEdit;
